<template>
    <div id="currency-select"
         class="w-100"
         :class="{ 'short-version': isShort }">
        <div class="info">
            <div class="d-flex justify-content-center align-items-center flex-column">
                <div class="w-100">
                    <div class="d-flex justify-content-center align-items-center position-relative mb-2">
                        <SearchIcon />
                        <input v-model="search"
                               :placeholder="getTrans('header.search', 'Search')"
                               class="mb-0">
                    </div>
                    <div v-if="filteredFiatCurrencies.length > 0"
                         class="scroll no-languages">
                        <div v-if="!isConverter"
                             class="currencies-container">
                            <a v-for="fiatCurrency in filteredFiatCurrencies"
                               :key="fiatCurrency.name"
                               :href="'?currency=' + fiatCurrency.currency_code"
                               class="language"
                               rel="nofollow noindex">
                                <div class="flag-name">
                                    <img class="mr-2"
                                         :src="asset_cdn(fiatCurrency.logo, 32)"
                                         loading="lazy"
                                         :alt="fiatCurrency.name"
                                         :title="fiatCurrency.name"> {{ fiatCurrency.name }}
                                </div>
                                <div class="country-initials text-nowrap">
                                    {{ fiatCurrency.currency_code }} - {{ fiatCurrency.sign }}
                                </div>
                            </a>
                        </div>
                        <div v-if="isConverter"
                             class="currencies-container">
                            <a v-for="fiatCurrency in filteredFiatCurrencies"
                               :key="fiatCurrency.name"
                               class="language"
                               @click="changeFiatCurrency(fiatCurrency);">
                                <div class="flag-name">
                                    <img class="mr-2"
                                         :src="asset_cdn(fiatCurrency.logo, 32)"
                                         :alt="fiatCurrency.name"
                                         :title="fiatCurrency.name"> {{ fiatCurrency.name }}
                                </div>
                                <div class="country-initials text-nowrap">
                                    {{ fiatCurrency.currency_code }} - {{ fiatCurrency.sign }}
                                </div>
                            </a>
                        </div>
                    </div>
                    <div v-else
                         class="no-results-message">
                        <Translate translationKey="header.searchNoResults"
                                   fallback="No results found. Try a different search query." />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { computed, ref } from 'vue';
    import { getTrans } from '../../helpers/getTrans';
    import { asset_cdn } from '../../helpers/helpers';
    import SearchIcon from '../../components/icons/SearchIcon.vue';
    import { IFiatCurrency, IFiatCurrencySearch } from '../menu/types/menuFooterTypes';

    interface IFiatCurrencySearchProps extends IFiatCurrencySearch {
        isShort?: boolean,
        changeFiatCurrencyProp?: (selectedFiatCurrency) => void,
        closeTippy?: () => void,
    }

    const props = withDefaults(defineProps<IFiatCurrencySearchProps>(), {
        isShort: false,
        isConverter: false,
        changeFiatCurrencyProp: () => {},
        closeTippy: () => {},
    });

    const search = ref('');

    const filteredFiatCurrencies = computed((): Array<IFiatCurrency> => {
        const searchLower = search.value.toLowerCase();

        return props.fiatCurrencies.filter((fiatCurrency: IFiatCurrency) => {
            const nameLowercase = fiatCurrency.name.toLowerCase();
            const currencyCodeLowercase = fiatCurrency.currency_code.toLowerCase();

            return nameLowercase.includes(searchLower) || currencyCodeLowercase.includes(searchLower);
        });
    });

    const changeFiatCurrency = (fiatCurrency: IFiatCurrency) => {
        const currentFiatCurrency = fiatCurrency;
        let currentFiatCurrencyPrice = '0';

        props.fiatCurrencies.forEach((fiatCurrency: IFiatCurrency) => {
            if (fiatCurrency.name === currentFiatCurrency.name) {
                currentFiatCurrencyPrice = fiatCurrency.price;
            }
        });
        currentFiatCurrency.price = currentFiatCurrencyPrice;
        props.changeFiatCurrencyProp(currentFiatCurrency);
        props.closeTippy();
    };
</script>

<style scoped lang="scss">
@import "resources/sass/partials/variables";
@import "resources/sass/partials/currency-select";
</style>
