import { createApp, defineAsyncComponent } from 'vue';
import { createPinia } from 'pinia';
import VueSplide from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import '../../bootstrap';
import '../../helpers/themeSwitcherClick';
import '../../helpers/setDynamicVH';

import GlobalSearch from '../../global/menu/GlobalSearch.vue';
import FiatCurrencySearch from '../../global/top-menu/FiatCurrencySearch.vue';
import CurrencyLanguageSearch from '../../global/top-menu/CurrencyLanguageSearch.vue';
import GasPriceTooltip from '../../global/top-menu/GasPriceTooltip.vue';
import { vFocus } from '../../directives/vFocus';

const Translate = defineAsyncComponent(() => import('../../helpers/Translate.vue'));
const LatestNewsAndVideos = defineAsyncComponent(() => import('../../global/widgets/LatestNewsAndVideos.vue'));
const CompanionBubble = defineAsyncComponent(() => import('../../global/top-menu/CompanionBubble.vue'));
const MarketingCampaignComponents = defineAsyncComponent(() => import('../../marketing-campaigns/MarketingCampaignComponents.vue'));
const ConnectModal = defineAsyncComponent(() => import('../../global/menu/ConnectModal.vue'));
const MobileMenuToggleButton = defineAsyncComponent(() => import('../../global/buttons/MobileMenuToggleButton.vue'));
const PromoNotifications = defineAsyncComponent(() => import('../../global/top-menu/PromoNotifications.vue'));
const StoreMenuData = defineAsyncComponent(() => import('../../global/menu/partials/StoreMenuData.vue'));
const MainMenu = defineAsyncComponent(() => import('../../global/menu/MainMenu.vue'));
const SubscribeNewsletterFooterForm = defineAsyncComponent(() => import('../../subscribe/SubscribeNewsletterFooterForm.vue'));

const app = createApp({});

app.directive('focus', vFocus);

app.use(createPinia());

app.use(VueSplide);
app.use(VueToast);

// Helpers
app.component('Translate', Translate);
app.config.globalProperties.Translate = Translate.methods;

// Global
app.component('StoreMenuData', StoreMenuData);
app.component('MainMenu', MainMenu);
app.component('MobileMenuToggleButton', MobileMenuToggleButton);
app.component('GlobalSearch', GlobalSearch);
app.component('FiatCurrencySearch', FiatCurrencySearch);
app.component('CurrencyLanguageSearch', CurrencyLanguageSearch);
app.component('PromoNotifications', PromoNotifications);
app.component('GasPriceTooltip', GasPriceTooltip);
app.component('ConnectModal', ConnectModal);
app.component('LatestNewsAndVideos', LatestNewsAndVideos);
app.component('CompanionBubble', CompanionBubble);
app.component('MarketingCampaignComponents', MarketingCampaignComponents);
app.component('SubscribeNewsletterFooterForm', SubscribeNewsletterFooterForm);

const mountApp = () => {
    app.mount('#app-mount');
};

export { mountApp, app };
