<template>
    <div ref="languageButtonRef"
         @click="openModal">
        <div class="currency-selection d-flex justify-content-center align-items-center">
            <img v-if="locales"
                 class="mr-1 border rounded-circle"
                 :src="asset_cdn(locales[currentLocale].logo, 15)"
                 width="15"
                 height="15"
                 :alt="currentCurrency.name">
            <div class="text-uppercase">
                {{ currentLocale }} -&nbsp;
            </div>
            <div>
                {{ currentCurrency.currency_code }}
            </div>
            <div>
                <i class="fa-solid fa-angle-down pl-1" />
            </div>
        </div>
    </div>
    <Teleport to="body">
        <Transition name="modal-fade">
            <CurrencyLanguageSearchModal v-if="showModal" />
        </Transition>
    </Teleport>
</template>

<script setup lang="ts">
    import { h, ref, watchEffect } from 'vue';
    import { asset_cdn } from '../../helpers/helpers';
    import useTippyPopup from '../../composables/useTippyPopup';
    import CurrencyLanguageSearchContent from './CurrencyLanguageSearchContent.vue';
    import { useDeviceSize } from '../../composables/useDeviceSize';
    import { useMenuStore } from '../../store/menu/MenuStore';
    import CurrencyLanguageSearchModal from '../menu/mobile-menu-footer/search/CurrencyLanguageSearchModal.vue';
    import { ICurrencyLanguageSearch } from '../menu/types/menuFooterTypes';

    interface ICurrencyLanguageSearchProps extends ICurrencyLanguageSearch {
        showModal?: boolean,
        isShort?: boolean,
        hasModal?: boolean, // TODO: remove after #5382
    }

    const props = withDefaults(defineProps<ICurrencyLanguageSearchProps>(), {
        hasModal: true,
    });

    const menuStore = useMenuStore();
    const { isMobileOrTablet } = useDeviceSize();

    const languageButtonRef = ref(null);

    const openModal = () => {
        menuStore.isCurrencySearchModalOpen = true;
    };

    const { disable, enable } = useTippyPopup(languageButtonRef, {
        content: h(CurrencyLanguageSearchContent, {
            currencies: props.currencies,
            currentCurrency: props.currentCurrency,
            currentLocale: props.currentLocale,
            locales: props.locales,
            currentRouteUrls: props.currentRouteUrls,
            isShort: props.isShort,
        }),
        placement: 'top',
        trigger: 'click',
        interactive: true,
    });

    watchEffect(() => {
        if (props.hasModal) {
            if (isMobileOrTablet.value) {
                disable();
            } else {
                enable();
            }
        }
    });
</script>

<style scoped lang="scss">
.modal-fade-enter-active,
.modal-fade-leave-active {
    transition: opacity 300ms ease;
}

.modal-fade-enter-from,
.modal-fade-leave-to {
    opacity: 0;
}
</style>
