<template>
    <div class="search-container">
        <i class="fa-solid fa-magnifying-glass" />
        <input v-model="model"
               type="text"
               maxlength="30"
               :placeholder="getTrans('header.search','Search')">
    </div>
</template>

<script setup lang="ts">
    import { getTrans } from '../../../../helpers/getTrans';

    const model = defineModel({ type: String });
</script>

<style scoped lang="scss">
@import "resources/sass/partials/variables";

.search-container {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    border: 1px solid $purple;
    border-radius: 8px;
    margin-bottom: 8px;
    font-size: 16px;

    i {
        margin-right: 4px;
        color: $purple;
    }

    input {
        display: flex;
        overflow: hidden;
        flex-grow: 1;
        border: none;
        outline: none;

        &::placeholder {
            color: $purple;
            font-weight: 600;
        }
    }
}
</style>
